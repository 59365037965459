import React, { useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Button from "../atoms/Button"
import Card from "../atoms/Card"
import Link from "../utils/Link"
import Icon from "../atoms/Icon"
import Flex from "../atoms/Flex"
import { Title } from "../atoms/Typography"
import RichText from "../atoms/RichText"
import Caret from "../icons/Caret"
import styled, { ThemeContext } from "styled-components"
import { compose, shadow } from "styled-system"

const StyledLink = styled(Link)`
  ${compose(shadow)};
  &:hover {
    & .Card {
      ${props =>
        props.onHoverShadow ? `box-shadow:${props.onHoverShadow}` : null};
      & .linkIndicator {
        transform: translateX(5px);
      }
    }
  }
`

const InfoCard = ({
  title,
  infoCardsTitleSize,
  infoCardsTitleColor,
  icon,
  button,
  align = "left",
  card,
  iconLocation,
  iconSize,
  paragraphRichText,
  link,
  ...rest
}) => {
  const center = align === "center"
  const isIconLeft = iconLocation === "left"
  const theme = useContext(ThemeContext)

  //setting a darker on-hover box-shadow
  const largeShadow = theme.shadows.lg.split(" ")
  const largeShadowColor = largeShadow[largeShadow.length - 1].split(",")
  let darkerShadowColor =
    parseFloat(largeShadowColor[largeShadowColor.length - 1]) * 1.6 //1.2
  darkerShadowColor = isNaN(darkerShadowColor) ? 0.1 : darkerShadowColor // setting an alpha fallback
  largeShadowColor[largeShadowColor.length - 1] = `${darkerShadowColor})`
  largeShadow[largeShadow.length - 1] = largeShadowColor.join(",")
  const onHoverShadow = largeShadow.join(" ")

  return (
    <StyledLink optional onHoverShadow={onHoverShadow} {...link}>
      <Card
        className="Card"
        maxWidth={44}
        transitionDuration="fast"
        transitionTimingFunction="ease-in-out"
        transitionProperty="box-shadow"
        {...rest}
        {...card}
      >
        <Flex
          px={4}
          py={card?.useCard ? 6 : 4}
          alignItems={center && !isIconLeft ? "center" : null}
          flexDirection={isIconLeft ? "row" : "column"}
        >
          {icon && (
            <Icon
              flexShrink={0}
              {...icon}
              mr={isIconLeft ? 4 : 0}
              mb={isIconLeft ? 0 : 4}
              width={iconSize ? `${iconSize}px` : 2}
              height={iconSize ? `${iconSize}px` : 2}
            />
          )}
          <Flex flexDirection="column" alignItems={center ? "center" : null}>
            <Title
              textAlign={center ? "center" : null}
              variant={
                infoCardsTitleSize === "h3" || infoCardsTitleSize === "h2"
                  ? infoCardsTitleSize
                  : "h3"
              }
              color={infoCardsTitleColor}
            >
              {title}
              {link && (
                <Caret
                  className="linkIndicator"
                  ml={3}
                  mt={-1.5}
                  direction="right"
                  width="16px"
                  height="16px"
                  transitionDuration="fast"
                  transitionTimingFunction="ease-in-out"
                  transitionProperty="transform"
                />
              )}
            </Title>
            {paragraphRichText?.content && (
              <RichText
                mt={title?.length > 0 ? 3 : 0}
                textAlign={center ? "center" : null}
                {...paragraphRichText}
              />
            )}
            {button && <Button {...button} mt={3} />}
          </Flex>
        </Flex>
      </Card>
    </StyledLink>
  )
}

export default InfoCard

InfoCard.strapiProps = {
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
  icon: PropTypes.shape(Icon.strapiProps),
  button: PropTypes.shape(Button.strapiProps),
  paragraphRichText: PropTypes.object,
}

InfoCard.propTypes = {
  ...InfoCard.strapiProps,
  ...Card.propTypes,
  align: PropTypes.oneOf(["left", "center"]).isRequired,
  iconLocation: PropTypes.oneOf(["left", "top"]),
}

export const query = graphql`
  fragment InfoCard on Strapi_ComponentMoleculesInfoCard {
    title
    icon {
      ...Icon
    }
    button {
      ...Button
    }
    paragraphRichText {
      ...RichText
    }
    link {
      ...Link
    }
  }
`
