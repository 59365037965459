import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"

import FilteredGrid from "../molecules/FilteredGrid"
import SectionTitle from "../molecules/SectionTitle"
import ImageCard from "../molecules/ImageCard"

const ImageGrid = ({
  section,
  sectionTitle,
  flexGrid,
  imageCards,
  card,
  useCardFront,
  textBackground = "solid", // enums: solid, gradient
  textVisibility = "visible", //enums: none, onHover, visible,
  alignText = "center",
  imageGridImageRatio,
  imageRatio,
  filter,
  filters,
}) => {
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FilteredGrid
        flexGrid={flexGrid}
        filters={filters}
        filtersStyles={{
          filterLocation: filter?.filterLocation,
          alignMenuItems: filter?.alignMenuItems,
          showFilterLabel: filter?.showFilterLabel,
          layout: filter?.layout,
          dropdownCard: filter?.dropdownCard,
        }} // only sending style fields to avoid possibly overwriting filter data
        card={{
          theme: section.theme,
          bg: section.bg,
          borderRadius: "md",
          // boxShadow: "md",
          boxShadow: "0 3px 10px rgb(0 0 0 / 30%)",
        }}
      >
        {imageCards &&
          imageCards?.map((imageCard, i) => {
            return (
              <ImageCard
                key={i}
                {...imageCard}
                alignText={alignText}
                card={{ ...card, useCard: useCardFront }}
                columns={flexGrid.columns}
                textVisibility={textVisibility}
                textBackground={textBackground}
                imageRatio={imageGridImageRatio || imageRatio}
              />
            )
          })}
      </FilteredGrid>
    </Section>
  )
}

export default ImageGrid

ImageGrid.strapiProps = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  imageCards: PropTypes.arrayOf(PropTypes.shape(ImageCard.strapiProps)),
  useCardFront: PropTypes.bool,
  alignText: PropTypes.oneOf(["left", "center"]),
  textVisibility: PropTypes.oneOf(["none", "onHover", "visible"]),
  textBackground: PropTypes.oneOf(["solid", "gradient"]),
  ...FilteredGrid.strapiProps,
}

ImageGrid.propTypes = {
  ...ImageGrid.strapiProps,
}

export const query = graphql`
  fragment ImageGrid on Strapi_ComponentSectionsImageGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    card {
      ...Card
    }
    imageCards {
      ...ImageCard
    }
    filters {
      ...Filter
    }
    filter {
      alignMenuItems
      filterLocation
      showFilterLabel
      layout
      dropdownCard {
        ...Card
      }
    }
    useCardFront
    alignText
    textVisibility
    textBackground
    imageGridImageRatio: imageRatio
  }
`
