import React, { useContext } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import { compose, layout } from "styled-system"

import Image from "../utils/Image"
import Link from "../utils/Link"

import Box from "../atoms/Box"
import Card from "../atoms/Card"
import Flex from "../atoms/Flex"
import { Title, Paragraph } from "../atoms/Typography"
import ProportionalBox from "../atoms/ProportionalBox"

const NameBox = ({ name, positionTitle, textClassName, center, ...rest }) => {
  return (
    <Box
      display={center ? "flex" : "block"}
      flexDirection="column"
      justifyContent="center"
      width="100%"
      textAlign={center ? "center" : "left"}
      className="name-box"
      {...rest}
    >
      <Title className={textClassName} variant="h3" mb={2}>
        {name}
      </Title>
      {positionTitle && (
        <Paragraph className={textClassName}>{positionTitle}</Paragraph>
      )}
    </Box>
  )
}

const StyledCard = styled(Card)`
  position: relative;
  .name-box {
    opacity: ${props => (props.$showNameOnHover ? 0 : 1)};
    transition-property: opacity;
  }
  .image-container {
    .image {
      transition: transform 0.3s ease;
      will-change: transform;

      &:hover {
        ${props => (props.zoomImageOnHover ? "transform:scale(1.05)" : null)};
      }
    }
  }

  &:hover {
    .name-box {
      ${props => (props.$showNameOnHover ? "opacity: 1" : null)};
    }
  }
`

const StyledOverlayImage = styled(Image)`
  opacity: 0;
  /* transition: opacity 0.3s cubic-bezier(0.34, 0.61, 0.48, 0.94); */
  transition: opacity 0.3s ease;
  will-change: opacity;
  &:hover {
    opacity: 1;
  }
`

const CircleLink = styled(Link)`
  .name {
    transition: opacity 0.3s ease;
    will-change: opacity;
  }
  &:hover .name {
    opacity: 0.6;
  }
  & .image img {
    transition: transform 0.3s ease;
    will-change: transform;
  }
  &:hover .image img {
    transform: scale(1.08);
  }
`

const CardLink = styled(Link)`
  .name-box {
    transition: opacity 0.4s ease;
    will-change: opacity;
  }
  & .name-box:hover {
    opacity: 0.6;
  }
  .image {
    transition: transform 0.4s ease;
    will-change: transform;
  }

  &:hover .image {
    ${props => (props.zoomImageOnHover ? "transform:scale(1.05)" : null)};
  }
`

const StyledImage = styled(Image)`
  ${compose(layout)}
`

const TeamCard = ({
  name,
  positionTitle,
  image,
  overlayImage,
  card,
  imageShape,
  link,
  showNameOnHover,
  align,
  imageWidth = "full",
  imageRatio,
  zoomImageOnHover,
}) => {
  const circle = imageShape === "circle"
  const center = align === "center"

  const currentTheme = useContext(ThemeContext)
  const bgColor =
    currentTheme?.themes[card?.theme]?.colors?.background[card?.bg] || "#FFFFFF"

  const getImageRatio = imageRatio => {
    switch (imageRatio) {
      case "fourThree":
        return 0.75
        break
      case "oneOne":
        return 1
        break
      case "threeTwo":
        return 0.66
        break
      case "sixteenNine":
        return 0.5625
        break
      default:
        return 0.75
    }
  }
  if (circle) {
    return (
      <CircleLink optional {...link} zoomImageOnHover={false}>
        <Card boxShadow="none" bg="transparent" overflow="visible">
          <Flex
            flexDirection={center ? "column" : "row"}
            alignItems="center"
            position="relative"
          >
            {image && (
              <Box flex="0 0 auto" mb={center ? 4 : 0} height={6} width={6}>
                <Image
                  className="image"
                  {...image}
                  style={{
                    height: "100%",
                    borderRadius: "50%",
                    willChange: "transform",
                    margin: "0.5px",
                  }}
                  imgStyle={{
                    borderRadius: "50%",
                  }}
                />
              </Box>
            )}
            {overlayImage && (
              <Box height={6} width={6} position="absolute">
                <StyledOverlayImage
                  className="image"
                  {...overlayImage}
                  style={{
                    height: "100%",
                    borderRadius: "50%",
                    margin: "0.5px",
                    zIndex: 100,
                  }}
                  imgStyle={{
                    borderRadius: "50%",
                  }}
                />
              </Box>
            )}
            <NameBox
              center={center}
              className="name"
              name={name}
              positionTitle={positionTitle}
              ml={!center && image && 4}
            />
          </Flex>
        </Card>
      </CircleLink>
    )
  }

  return (
    <CardLink optional {...link} zoomImageOnHover={zoomImageOnHover}>
      <StyledCard
        height="100%"
        width="100%"
        $showNameOnHover={showNameOnHover}
        zoomImageOnHover={zoomImageOnHover}
        {...card}
        bg={null}
      >
        <ProportionalBox
          display="flex"
          flex={1.8}
          ratio={getImageRatio(imageRatio)}
          className="image-container"
          transitionProperty="height, transform"
          transitionTimingFunction="ease"
          transitionDuration="fast"
          overflow="hidden"
        >
          {showNameOnHover && (
            <NameBox
              zIndex={200}
              textAlign={center ? "center" : "left"}
              textClassName="name"
              name={name}
              positionTitle={positionTitle}
              position="absolute"
              height={5}
              width="100%"
              bottom="0"
              padding={4}
              pt={imageWidth === "full" ? 3 : 1}
              transitionDuration="fast"
              transitionTimingFunction="ease"
              bg={card?.useCard === true ? bgColor : null}
            />
          )}
          <Box
            className="image"
            height="100%"
            transitionProperty="height, transform"
            transitionTimingFunction="ease"
            transitionDuration="md"
            overflow="hidden"
          >
            {image && (
              <StyledImage
                className="image"
                {...image}
                style={{
                  height: "100%",
                  width: "100%",
                }}
                imgStyle={{
                  padding: imageWidth === "full" ? "0px" : "24px",
                }}
                minHeight={!card?.useCard ? "100%" : "auto"}
              />
            )}
            {overlayImage && (
              <StyledOverlayImage
                className="image"
                {...overlayImage}
                style={{
                  height: "100%",
                  left: 0,
                  top: 0,
                  bottom: 0,
                  position: "absolute",
                  zIndex: 100,
                }}
                imgStyle={{
                  padding: imageWidth === "full" ? "0px" : "24px",
                }}
              />
            )}
          </Box>
        </ProportionalBox>
        {!showNameOnHover && (
          <NameBox
            textAlign={center ? "center" : "left"}
            textClassName="name"
            name={name}
            positionTitle={positionTitle}
            position="relative"
            height="fit-content"
            width="100%"
            bottom="0"
            padding={4}
            pt={imageWidth === "full" ? 3 : 1}
            transitionDuration="fast"
            transitionTimingFunction="ease"
            bg={card?.useCard === true ? bgColor : null}
          />
        )}
      </StyledCard>
    </CardLink>
  )
}

export default TeamCard

TeamCard.strapiProps = {
  name: PropTypes.string.isRequired,
  positionTitle: PropTypes.string,
  image: PropTypes.object,
  tag: PropTypes.object,
}

TeamCard.propTypes = {
  ...TeamCard.strapiProps,
  imageShape: PropTypes.oneOf(["default", "square", "circle"]),
  showNameOnHover: PropTypes.bool,
  zoomImageOnHover: PropTypes.bool,
  card: PropTypes.shape(Card.strapiProps),
  link: PropTypes.shape(Link.strapiProps),
}

export const query = graphql`
  fragment TeamCard on Strapi_ComponentMoleculesTeamCard {
    name
    positionTitle
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    overlayImage {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    link {
      ...Link
    }
    tags {
      name
    }
  }
`
