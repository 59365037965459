import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Icon from "../atoms/Icon"
import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import { Paragraph, Label } from "../atoms/Typography"
import RichText from "../atoms/RichText"

const Quote = ({ quoteRichText, name, position, textAlign, logo, ...rest }) => {
  return (
    <Box mx={textAlign === "center" ? "auto" : 0} my={4} {...rest}>
      {quoteRichText?.content && (
        <RichText textAlign={textAlign} mb={6} {...quoteRichText} />
      )}
      {name && (
        <Label align={textAlign} mb={3}>
          {name}
        </Label>
      )}
      {position && (
        <Paragraph align={textAlign} mb={logo && 4}>
          {position}
        </Paragraph>
      )}
      {logo && (
        <Flex justifyContent={textAlign === "center" ? "center" : "flex-start"}>
          <Icon {...logo} maxWidth={7} height="100%" />
        </Flex>
      )}
    </Box>
  )
}

export default Quote

Quote.strapiProps = {
  quoteRichText: PropTypes.object,
  name: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.object,
}

export const query = graphql`
  fragment Quote on Strapi_ComponentMoleculesQuote {
    name
    position
    quoteRichText {
      ...RichText
    }
    logo {
      ...Icon
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    backgroundImage {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    backgroundImageMobile {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  }
`
