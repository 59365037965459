import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { format } from "date-fns"

import Link from "../utils/Link"
import Image from "../utils/Image"

import Card from "../atoms/Card"
import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import ProportionalBox from "../atoms/ProportionalBox"
import { Title, Paragraph, Label } from "../atoms/Typography"

const Post = ({
  title,
  description,
  thumbnail,
  date,
  link,
  card,
  showDate,
  thumbnailFitType,
}) => {
  let dateUpdated = new Date(date)
  const timezoneOffset = dateUpdated.getTimezoneOffset()
  dateUpdated = new Date(dateUpdated.getTime() + timezoneOffset * 60000)
  const month = dateUpdated.getMonth() + 1
  const day = dateUpdated.getDate()
  const dateString = `${dateUpdated.getFullYear()}-${
    (month < 10 ? "0" : "") + month
  }-${(day < 10 ? "0" : "") + day}`

  return (
    <Link {...link}>
      <Card height="100%" {...card}>
        {thumbnail && (
          <ProportionalBox>
            {thumbnailFitType === "width" ? (
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
                bg={"background.1"}
              >
                <Image
                  {...thumbnail}
                  style={{ width: "100%" }}
                  imgStyle={{ objectFit: "cover" }}
                />
              </Flex>
            ) : (
              <Image
                {...thumbnail}
                style={{ height: "100%" }}
                imgStyle={{ objectFit: "cover" }}
              />
            )}
          </ProportionalBox>
        )}
        <Box px={{ _: 4, sm: 5 }} py={4}>
          <Title variant="h3" mb={3}>
            {title}
          </Title>
          {description && <Paragraph>{description}</Paragraph>}
          {showDate && date && (
            <Label mt={4} opacity={0.5}>
              {format(new Date(dateString), "MMM d, y")}
            </Label>
          )}
        </Box>
      </Card>
    </Link>
  )
}

export default Post

Post.strapiProps = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  thumbnail: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.shape(Link.strapiProps),
  tag: PropTypes.object,
}

Post.propTypes = {
  ...Post.strapiProps,
}

export const query = graphql`
  fragment Post on Strapi_ComponentMoleculesPosts {
    title
    description
    thumbnail {
      url
      alternativeText
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    thumbnailFitType
    date
    link {
      ...Link
    }
    tags {
      name
    }
  }
`
