import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import SectionTitle from "../molecules/SectionTitle"
import Button from "../atoms/Button"

const ImageTextCarouselItem = ({
  text,
  button,
  button2,
  textAlign,
  ...rest
}) => {
  return (
    <Box mx={textAlign === "center" ? "auto" : 0} {...rest}>
      {text && <SectionTitle {...text} align={textAlign} as="p"  />}
      {(button || button2) && (
        <Flex justifyContent={textAlign} mt={4}>
          {button && <Button mr={button2 ? 5 : 0} {...button} />}
          {button2 && <Button {...button2} />}
        </Flex>
      )}
    </Box>
  )
}

export default React.memo(ImageTextCarouselItem)

ImageTextCarouselItem.strapiProps = {
  text: PropTypes.shape(SectionTitle.strapiProps),
  button: PropTypes.shape(Button.strapiProps),
  button2: PropTypes.shape(Button.strapiProps),
  imageOrVideo: PropTypes.object,
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
}

export const query = graphql`
  fragment ImageTextCarouselItem on Strapi_ComponentMoleculesImageTextCarouselItem {
    button {
      ...Button
    }
    button2 {
      ...Button
    }
    text {
      ...SectionTitle
    }
    imageOrVideo {
      ...ImageOrVideo
    }
    backgroundImage {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
    backgroundImageMobile {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
    }
  }
`
