import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Reveal from "../utils/Reveal"

import Button from "../atoms/Button"
import Card from "../atoms/Card"
import FlexGrid from "../atoms/FlexGrid"
import Flex from "../atoms/Flex"
import Filter from "../atoms/Filter"

const NUM_TO_LOAD = 6

const FilteredGrid = ({
  centerFilter,
  filters = [],
  filtersStyles,
  flexGrid,
  children,
  loadMoreBtnStyles,
}) => {
  centerFilter = centerFilter
    ? centerFilter
    : filtersStyles?.filterLocation
    ? filtersStyles?.filterLocation
    : "left"

  const [numLoaded, setNumLoaded] = useState(NUM_TO_LOAD)
  const childrenArr = React.Children.toArray(children)
  const [filteredChildrenArr, setFilteredChildrenArr] = useState(childrenArr)
  const [selectedFilters, setSelectedFilters] = useState({})

  const loadMore = startWith => {
    const newNum = startWith + NUM_TO_LOAD
    setNumLoaded(newNum)
  }

  const getSlicedChildrens = () => {
    if (loadMoreBtnStyles?.showBtn) {
      return filteredChildrenArr.slice(0, numLoaded)
    }
    return filteredChildrenArr
  }

  const updateFilters = (tag, filter) => {
    setSelectedFilters(prevState => {
      const updatedState = {
        ...prevState,
        [filter.allLabel]: tag?.[filter.allLabel],
      }
      if (!tag?.[filter.allLabel]) {
        delete updatedState[filter.allLabel]
      }

      return updatedState
    })
  }

  const updateGrid = () => {
    const selFilters = Object.values(selectedFilters).filter(tag => tag)
    if (selFilters.length > 0) {
      setFilteredChildrenArr(
        prevFilteredChildrenArr => {
          return prevFilteredChildrenArr.filter(child =>
            Object.keys(selectedFilters).every(SelFilKey => {
              if (!selectedFilters[SelFilKey]) {
                return true
              }
              return child.props?.tags
                ?.map(({ name }) => name)
                .includes(selectedFilters[SelFilKey])
            })
          )
        },
        loadMore(0) //reseting initial items loaded amount
      )
    }
  }

  const handleJustification = alignmentStr => {
    switch (alignmentStr) {
      case "center":
        return "center"
      case "left":
        return "flex-start"
      case "right":
        return "flex-end"
      default:
        return "flex-start"
    }
  }

  useEffect(() => {
    //this useEffect allow the grid to update children content when comp do not uses the filter prop
    if (!filters?.length) {
      setFilteredChildrenArr(
        childrenArr,
        loadMore(0) //reseting initial items loaded amount
      )
    }
  }, [children])

  useEffect(() => {
    //useEffect to update grid when filters option is turned on
    setFilteredChildrenArr(
      childrenArr,
      loadMore(0) //reseting initial items loaded amount)
    )
    updateGrid()
  }, [selectedFilters])

  return (
    <div>
      {filters && (
        <Reveal>
          <Flex justifyContent={handleJustification(centerFilter)} mb={6}>
            {filters.map(filter => (
              <Filter
                center={centerFilter}
                onChange={tag => {
                  updateFilters(tag, filter)
                }}
                filterItems={children}
                {...filter}
                {...filtersStyles}
                mr={5}
              />
            ))}
          </Flex>
        </Reveal>
      )}
      <FlexGrid {...flexGrid}>
        {getSlicedChildrens().map((child, i) => (
          <div key={`${child.props.forwardKey}-${i}`}>{child}</div>
        ))}
      </FlexGrid>
      {loadMoreBtnStyles?.showBtn && filteredChildrenArr.length > numLoaded && (
        <Flex mt={8} flexDirection="column" {...loadMoreBtnStyles}>
          <Button text="Load More" onClick={() => loadMore(numLoaded)} />
        </Flex>
      )}
    </div>
  )
}

FilteredGrid.strapiProps = {
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  filters: PropTypes.arrayOf(PropTypes.shape(Filter.strapiProps)),
  filter: PropTypes.shape(Filter.strapiProps),
}

export default FilteredGrid
