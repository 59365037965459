import React, { useContext } from "react"
import { ThemeContext } from "styled-components"
import PropTypes from "prop-types"

import Box from "../atoms/Box"

import ButtonElement from "../atoms/ButtonElement"

const Indicator = ({
  active,
  onClick,
  variant,
  maxWidth,
  indicatorStyle,
  ...rest
}) => {
  let currentTheme = useContext(ThemeContext)

  let indicator = currentTheme.icons?.indicator
    ? currentTheme.icons?.indicator
    : indicatorStyle
  switch (indicator) {
    case "circle":
      return (
        <ButtonElement
          transitionProperty="opacity"
          transitionDuration="fast"
          opacity={active ? 1 : 0.25}
          onClick={onClick}
          p={2}
          {...rest}
        >
          <Box
            style={{ borderRadius: "100%" }}
            width="8px"
            height="8px"
            bg="primary"
          />
        </ButtonElement>
      )
    case "sleek":
      return (
        <ButtonElement
          transitionProperty="opacity"
          transitionDuration="fast"
          opacity={active ? 1 : 0.25}
          onClick={onClick}
          py={3}
          maxWidth={maxWidth}
          {...rest}
        >
          <Box maxWidth="100%" bg="primary" height="2px" width="36px" />
        </ButtonElement>
      )
      break
    default:
      return (
        <ButtonElement
          transitionProperty="opacity"
          transitionDuration="fast"
          opacity={active ? 1 : 0.25}
          bg="primary"
          onClick={onClick}
          height="4px"
          width="24px"
          {...rest}
        />
      )
  }
}

Indicator.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Indicator
