import React from "react"
import { graphql } from "gatsby"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import Icon from "../atoms/Icon"

import GenericImageCarousel from "../molecules/GenericImageCarousel"
import Quote from "../molecules/Quote"

const QuoteCarousel = ({
  section,
  sectionTitle,
  quotes,
  quoteCarouselImageLocation,
  imageLocation,
  showArrows,
  quoteCarouselShowArrows,
  showIndicators,
  quoteCarouselShowIndicators,
  controlsLocation,
  quoteCarouselControlsLocation,
  controlsAlignment,
  quoteCarouselControlsAlignment,
  textAlign,
  quoteCarouselTextAlign,
  showImages,
  quoteCarouselShowImages,
  rotateSpeed,
  quoteCarouselRotateSpeed,
  textPosition,
  quoteCarouselTextPosition,
  card,
  useCardFront,
  icon,
}) => {
  card = { ...card, useCard: useCardFront }
  const contentPosition = textPosition || quoteCarouselTextPosition
  const textAlignProp = textAlign || quoteCarouselTextAlign
  const imageLocationProp = imageLocation || quoteCarouselImageLocation
  const MAX_WIDTH_TEXT = 44

  quotes = quotes?.map(({ image, ...rest }) => {
    const imageOrVideo = {
      desktopImage: image,
      imageStyles: {
        objectFit: "contain",
      },
    }

    const textChild = (
      <Flex
        flexDirection="column"
        height="100%"
        m={card.useCard === false ? "16px 0px" : null}
      >
        <Flex
          height="100%"
          justifyContent={textAlignProp === "left" ? "flex-start" : "center"}
        >
          <Box
            alignSelf={
              contentPosition === "top"
                ? "flex-start"
                : contentPosition === "center"
                ? "center"
                : contentPosition === "bottom"
                ? "flex-end"
                : "center"
            }
          >
            {icon && (
              <Box mr={6}>
                <Icon {...icon} width="40px" height="40px" />
              </Box>
            )}
            <Quote
              maxWidth={MAX_WIDTH_TEXT}
              textAlign={textAlignProp}
              {...rest}
            />
          </Box>
        </Flex>
      </Flex>
    )
    return { textChild, imageOrVideo, ...rest }
  })
  return (
    <GenericImageCarousel
      section={section}
      sectionTitle={sectionTitle}
      items={quotes}
      card={card}
      mediaLocation={imageLocationProp}
      showMedia={showImages || quoteCarouselShowImages}
      controlsLocation={controlsLocation || quoteCarouselControlsLocation}
      controlsAlignment={controlsAlignment || quoteCarouselControlsAlignment}
      controls={showArrows || quoteCarouselShowArrows}
      indicators={showIndicators || quoteCarouselShowIndicators}
      rotateSpeed={rotateSpeed || quoteCarouselRotateSpeed}
      // textAlign={textAlignProp}
      imageTextMolecule={{
        location: imageLocationProp === "left" ? "right" : "left",
        align: !card.useCard
          ? contentPosition === "top"
            ? "start"
            : contentPosition === "center"
            ? "center"
            : contentPosition === "bottom"
            ? "end"
            : "center"
          : "center",
        card: { useCard: false },
        layoutStyle: "standard",
        child2Style: {
          maxHeight: card.useCard ? "250px" : "400px",
          height: "100%",
          flex: card.useCard ? "0 0 25%" : 1,
        },
        mediaPositioning: "floating",
        layoutChangerStyles: { height: "100%", width: "100%" },
      }}
    />
  )
}

export default QuoteCarousel

export const query = graphql`
  fragment QuoteCarousel on Strapi_ComponentSectionsQuoteCarousel {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    quotes {
      ...Quote
    }
    quoteCarouselTextPosition: textPosition
    card {
      ...Card
    }
    icon {
      ...Icon
    }
    useCardFront
    quoteCarouselImageLocation: imageLocation
    quoteCarouselControlsLocation: controlsLocation
    quoteCarouselControlsAlignment: controlsAlignment
    quoteCarouselShowImages: showImages
    quoteCarouselShowArrows: showArrows
    quoteCarouselShowIndicators: showIndicators
    quoteCarouselTextAlign: textAlign
    quoteCarouselRotateSpeed: rotateSpeed
  }
`
