import React, { useEffect, useState, useRef } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import ButtonElement from "../atoms/ButtonElement"
import Flex from "../atoms/Flex"
import PopUpMenu from "../atoms/PopUpMenu"
import { Text } from "../atoms/Typography"
import Box from "../atoms/Box"
import Card from "./Card"

import FilterTabs from "../molecules/FilterTabs"

import Caret from "../icons/Caret"

const BasicDropDown = ({
  menuOpen,
  options,
  anchorRef,
  name,
  showFilterLabel,
  center,
  selected,
  alignMenuItems,
  allLabel,
  dropdownCard,
  setMenuOpen,
  setSelected,
  ...rest
}) => {
  const handleSelectedDropdownStyle = () => {
    return {
      color: "text",
      fontSize: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "104px",
      whiteSpace: "nowrap",
      display: "inline-block",
      mr: 4,
    }
  }
  return (
    <>
      {showFilterLabel && (
        <Box mb={4}>
          <Text fontSize={1} color="text">
            {name}
          </Text>
        </Box>
      )}
      <Card {...dropdownCard}>
        <ButtonElement
          ref={anchorRef}
          onClick={() => setMenuOpen(true)}
          pt={4}
          pl={4}
          pb={4}
          pr={5}
          {...rest}
          mr={0}
        >
          <Flex minWidth={8} alignItems="center" justifyContent="space-between">
            <Text {...handleSelectedDropdownStyle()}>
              {selected?.[allLabel] || allLabel}
            </Text>
            <Caret color="text" />
          </Flex>
        </ButtonElement>
        <PopUpMenu
          center={center}
          anchorEl={anchorRef.current}
          show={menuOpen}
          active={selected?.[allLabel]}
          onSelect={value => {
            setSelected({ [allLabel]: value })
          }}
          handleClose={() => setMenuOpen(false)}
          options={options}
          card={dropdownCard}
          alignMenuItems={alignMenuItems}
        />
      </Card>
    </>
  )
}

const Filter = ({
  name,
  allLabel,
  center,
  tags,
  onChange,
  filterItems,
  alignMenuItems,
  showFilterLabel,
  layout,
  dropdownCard,
  ...rest
}) => {
  const [selected, setSelected] = useState({ [allLabel]: allLabel })
  const [menuOpen, setMenuOpen] = useState(false)
  const [options, setOptions] = useState([])
  const anchorRef = useRef(null)

  useEffect(() => {
    onChange(selected?.[allLabel] === allLabel ? null : selected)
  }, [filterItems, selected?.[allLabel]])

  useEffect(() => {
    let newOptions = []
    if (allLabel) newOptions = [allLabel]
    if (tags) {
      const sortedTags = [...tags].sort(
        (a, b) => b.created_at - a.created_at || b.name - a.name
      ) //temporarily sorting tags until we can update to strapi V4
      newOptions = [...newOptions, ...sortedTags.map(({ name }) => name)]
    }
    setOptions(newOptions)
  }, [allLabel, tags])

  return (
    <>
      {layout === "tabs" ? (
        <Flex flexDirection="column" justifyContent="space-between" mr={7}>
          <FilterTabs
            allLabel={allLabel}
            menuOpen={menuOpen}
            options={options}
            anchorRef={anchorRef}
            showFilterLabel={showFilterLabel}
            alignMenuItems={alignMenuItems}
            center={center}
            selected={selected}
            name={name}
            setSelected={setSelected}
            setMenuOpen={setMenuOpen}
            {...rest}
          />
        </Flex>
      ) : (
        <Flex flexDirection="column" justifyContent="space-between" mr={7}>
          <BasicDropDown
            allLabel={allLabel}
            menuOpen={menuOpen}
            options={options}
            anchorRef={anchorRef}
            showFilterLabel={showFilterLabel}
            alignMenuItems={alignMenuItems}
            center={center}
            selected={selected}
            name={name}
            dropdownCard={dropdownCard}
            setSelected={setSelected}
            setMenuOpen={setMenuOpen}
            mr={7}
            {...rest}
          />
        </Flex>
      )}
    </>
  )
}

Filter.strapiProps = {
  name: PropTypes.string,
  allLabel: PropTypes.string.isRequired,
  showFilterLabel: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.object),
}

export default Filter

export const query = graphql`
  fragment Filter on Strapi_ComponentAtomsFilter {
    name
    allLabel
    showFilterLabel
    layout
    tags {
      name
      created_at
    }
  }
`
