import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import FlexGrid from "../atoms/FlexGrid"
import Filter from "../atoms/Filter"
import Section from "../atoms/Section"

import Logo from "../molecules/Logo"
import LogoCarousel from "../molecules/LogoCarousel"
import FilteredGrid from "../molecules/FilteredGrid"
import SectionTitle from "../molecules/SectionTitle"

const LogoGrid = ({
  cardBack,
  cardFront,
  cardMaxHeight,
  customCardMaxHeight,
  customImageWidth,
  cardRevealAlign,
  cardRevealVerticalAlign,
  displayMode = "grid",
  filter,
  filters,
  flexGrid,
  logoItems,
  section,
  sectionTitle,
  useCardBack,
  useCardFront,
  zoomOnHover,
}) => {
  cardMaxHeight = cardMaxHeight === null ? "fullHeight" : cardMaxHeight
  customCardMaxHeight = customCardMaxHeight === null ? 328 : customCardMaxHeight

  cardFront = {
    ...cardFront,
    useCard: useCardFront,
  }
  cardBack = {
    ...cardFront,
    useCard: useCardBack,
    theme: cardBack?.theme,
    bg: cardBack?.bg,
  }
  const breakpoints = Object.keys(flexGrid?.columns)
  const columnsOnBiggestBp =
    flexGrid?.columns[`${breakpoints[breakpoints.length - 1]}`]

  const isScroll = displayMode === "scroll"
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={7} maxWidth={42} />
        </Reveal>
      )}
      {isScroll && (
        <LogoCarousel
          cardFront={cardFront}
          logoItems={logoItems}
          customImageWidth={customImageWidth}
        />
      )}
      {!isScroll && (
        <FilteredGrid
          flexGrid={flexGrid}
          filters={filters}
          filtersStyles={{
            filterLocation: filter?.filterLocation,
            alignMenuItems: filter?.alignMenuItems,
            showFilterLabel: filter?.showFilterLabel,
            layout: filter?.layout,
            dropdownCard: filter?.dropdownCard,
          }} // only sending style fields to avoid possibly overwriting filter data
        >
          {logoItems &&
            logoItems.map((logo, i) => (
              <Logo
                height="100%"
                logoBoxFrontCardPadding={{
                  _: 4,
                  md: columnsOnBiggestBp < 3 ? 7 : 5,
                }}
                zoomOnHover={zoomOnHover}
                cardFront={cardFront}
                cardBack={cardBack}
                align={cardRevealAlign}
                verticalAlign={cardRevealVerticalAlign}
                cardMaxHeight={cardMaxHeight}
                customCardMaxHeight={customCardMaxHeight}
                customImageWidth={customImageWidth}
                {...logo}
                key={i}
              />
            ))}
        </FilteredGrid>
      )}
    </Section>
  )
}

export default LogoGrid

LogoGrid.propTypes = {
  id: PropTypes.string.isRequired,
  cardBack: PropTypes.shape(Card.strapiProps),
  cardFront: PropTypes.shape(Card.strapiProps),
  cardMaxHeight: PropTypes.oneOf(["default", "square", "custom"]),
  cardRevealAlign: PropTypes.oneOf(["left", "center"]),
  cardRevealVerticalAlign: PropTypes.oneOf(["default", "center"]),
  customCardMaxHeight: PropTypes.number,
  displayMode: PropTypes.oneOf(["grid", "scroll"]),
  filters: PropTypes.arrayOf(PropTypes.shape(Filter.strapiProps)),
  filter: PropTypes.shape(Filter.strapiProps),
  flexGrid: PropTypes.shape(FlexGrid.strapiProps).isRequired,
  logoItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  useCardBack: PropTypes.bool,
  useCardFront: PropTypes.oneOf(["card", "noCard"]),
}

export const query = graphql`
  fragment LogoGrid on Strapi_ComponentSectionsLogoGrid {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    logoItems {
      ...Logo
    }
    cardFront {
      ...Card
    }
    cardBack {
      ...Card
    }
    filters {
      ...Filter
    }
    filter {
      alignMenuItems
      filterLocation
      showFilterLabel
      layout
      dropdownCard {
        ...Card
      }
    }
    useCardFront
    useCardBack
    cardRevealAlign
    cardRevealVerticalAlign
    displayMode
    zoomOnHover
    cardMaxHeight
    customCardMaxHeight
    customImageWidth
  }
`
