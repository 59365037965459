import React from "react"
import { graphql } from "gatsby"

import GenericImageCarousel from "../../molecules/GenericImageCarousel"

import * as Utils from "./utils"

const ImageTextCarousel = props => {
  const {
    verticalPadding,
    items,
    overlap,
    controlsLocation,
    mediaPositioning,
    conditionalProps,
    isMediaPositioningFloating,
    customPadding,
    // imageTextMoleculeProps,
  } = Utils.useMemoizedProps(props)

  const imageTextMoleculeProps = {
    align: props.imageTextCarouselAlign || props.align,
    card: props.card,
    child1Px: props.child1Px,
    layoutStyle: props.imageTextCarouselLayoutStyle || props.layoutStyle,
    location: props.imageTextCarouselTextLocation || props.location,
    mediaLocation: props.mediaLocation || props.imageTextCarouselMediaLocation,
    mediaPositioning:
      props.imageTextCarouselMediaPositioning || props.mediaPositioning,
    mediaPositioningOnMobile:
      props.imageTextCarouselMediaPositioningOnMobile ||
      props.mediaPositioningOnMobile,
    mediaRatio: props.mediaRatio,
    xOffsetOverlap: props.xOffsetOverlap,
    yOffsetOverlap: props.yOffsetOverlap,
    overlapOnMobile: false,
    // child2Style:props.child2Style
    // child1Style:props.child1Style
    // section:props.section,
  }

  const child1Style = {}
  const child2Style = {}

  const standardPaddingStyles = {}
  const layoutChangerStyles = {}

  layoutChangerStyles.height = { _: "100%", lg: "100%" }
  if (imageTextMoleculeProps.mediaPositioning === "floating") {
    child2Style.maxHeight = { _: "300px", lg: "400px" } //{ _: "300px", md: "400px" }

    if (
      imageTextMoleculeProps.location === "left" ||
      imageTextMoleculeProps.location === "right"
    ) {
      layoutChangerStyles.alignItems = "stretch"
    }
    child2Style.alignSelf = {
      _: "stretch",
      md: "inherit",
      // md: "center",
    }
  } else {
    standardPaddingStyles.px = { _: 5, md: 7, xl: 8 }

    if (props.section.verticalPadding === "fullHeight") {
      layoutChangerStyles.height = { _: "100%", md: "100vh" }
    }
    if (overlap) {
      child2Style.maxHeight = { _: "335px", md: "100%" }
      child1Style.pb = { _: "100px" } //adding padding btw controls and text (on fit positioning)
    } else {
      child2Style.maxHeight = { _: "400px", sm: "600px", md: "100%" }
      child1Style.pb = { _: 0, sm: "25px", md: "60px" } //adding padding btw controls and text (on fit positioning)
    }
  }

  standardPaddingStyles.maxWidth = "100%"

  const sectionProp = { ...props.section, verticalPadding }
  return (
    <GenericImageCarousel
      sectionTitle={props.sectionTitle}
      items={items}
      section={sectionProp}
      // mediaLocation={"right"}
      // mediaLocation={
      //   props.mediaLocation || props.imageTextCarouselMediaLocation
      // }
      showMedia={props.showMedia}
      controlsLocation={controlsLocation}
      controlsAlignment={
        props.controlsAlignment || props.imageTextCarouselControlsAlignment
      }
      controls={props.showArrows}
      indicators={props.showIndicators}
      rotateSpeed={props.rotateSpeed}
      textAlign={props.textAlign || props.imageTextCarouselTextAlign}
      imageTextMolecule={{
        // ...props, //maybe this can be removed
        ...imageTextMoleculeProps,
        section: sectionProp,
        layoutChangerStyles,
        standardPaddingStyles,
        child2Style,
        child1Style,
        overlapOnMobile: false,
        // ...imageTextMoleculeProps,
        //-----------------------
        // customPadding,
        // conditionalProps,
        // isMediaPositioningFloating,
        // overlap,
        // childrenStyles,
        // standardPaddingProps,
        // renderSectionTitle,
        // hasImageOrVideo,
        // renderImageOrVideo,
        // alignProp: "start",
        // ------------
      }}
    />
  )
}

export default React.memo(ImageTextCarousel)

export const query = graphql`
  fragment ImageTextCarousel on Strapi_ComponentSectionsImageTextCarousel {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    items {
      ...ImageTextCarouselItem
    }
    card {
      ...Card
    }
    imageOrVideoStyle {
      controls
      light
      playing
      loop
      volume
    }
    imageTextCarouselControlsLocation: controlsLocation
    imageTextCarouselControlsAlignment: controlsAlignment
    showMedia
    showArrows
    showIndicators
    imageTextCarouselTextAlign: textAlign
    rotateSpeed
    child1Px
    imageTextCarouselAlign: align
    imageTextCarouselTextLocation: location
    imageTextCarouselLayoutStyle: layoutStyle
    imageTextCarouselMediaLocation: mediaLocation
    imageTextCarouselMediaPositioning: mediaPositioning
    imageTextCarouselMediaPositioningOnMobile: mediaPositioningOnMobile
    mediaRatio
    xOffsetOverlap
    yOffsetOverlap
  }
`
