import React from "react"
import { graphql } from "gatsby"
import { format } from "date-fns"

import Image from "../utils/Image"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"
import { Title, Label, Paragraph } from "../atoms/Typography"
import RichText from "../atoms/RichText"

const Article = ({
  title,
  author,
  date,
  displayDate = true,
  section,
  image,
  richText,
}) => {
  if (displayDate === null) displayDate = true
  return (
    <Section {...section}>
      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        position={"relative"}
      >
        <Box
          width={"100%"}
          maxWidth={"968px"}
          maxHeight={"448px"}
          overflow={"hidden"}
        >
          <Image {...image} />
        </Box>
        <Box width={{ _: "95%", sm: "80%" }} maxWidth={"704px"}>
          <Title variant={"h1"} pt={6}>
            {title}
          </Title>
          {displayDate && (
            <Paragraph fontSize={0} pt={4}>
              {format(new Date(date), "MMMM d, yyyy")}
            </Paragraph>
          )}
          <Paragraph
            fontSize={0}
            pt={displayDate ? 3 : 4}
            fontWeight="medium"
            mb={7}
          >
            {author}
          </Paragraph>
          <RichText mb={6} isArticle {...richText} section={section} />
        </Box>
      </Flex>
    </Section>
  )
}

export default Article

export const query = graphql`
  fragment Article on Strapi_ComponentSectionsArticle {
    title
    author
    date
    displayDate
    richText {
      ...RichText
    }
    section {
      ...Section
    }
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 900, layout: CONSTRAINED)
        }
      }
    }
  }
`
