import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import LayoutChanger from "../atoms/LayoutChanger"
import Html from "../atoms/CustomHtml"
import Section from "../atoms/Section"

import SectionTitle from "../molecules/SectionTitle"
import Box from "../atoms/Box"

const CustomHtml = ({
  section,
  sectionTitle,
  alignHtml,
  integration,
  layoutChanger,
  customHtmlWidth,
}) => {
  return (
    <Section {...section}>
      <LayoutChanger
        child1Style={
          sectionTitle.titleMaxWidth === "fullWidth" ? { width: "100%" } : null
        }
        child2Style={{ maxWidth: "100%", maxHeight: "100%" }}
        {...layoutChanger}
      >
        <Box width="100%">
          {sectionTitle && <SectionTitle {...sectionTitle} maxWidth={44} />}
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems={customHtmlWidth > 0 ? "center" : "stretch"}
          overflow="hidden"
          width="100%"
          height="100%"
        >
          {integration?.data?.html && (
            <Html
              // mb={-5}
              // minWidth="100%"
              display="flex"
              flexDirection="column"
              alignItems={alignHtml === "center" ? "center" : "stretch"}
              html={integration.data.html}
              height="100%"
              width="100%"
              maxWidth={customHtmlWidth > 0 ? customHtmlWidth : "100%"}
              overflow="hidden"
            />
          )}
        </Box>
      </LayoutChanger>
    </Section>
  )
}

CustomHtml.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  integration: PropTypes.shape({
    type: PropTypes.oneOf(["customHtml"]),
    data: PropTypes.shape({ html: PropTypes.string }),
  }),
}

export default CustomHtml

export const query = graphql`
  fragment CustomHtml on Strapi_ComponentSectionsCustomHtml {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    integration {
      type
      data
    }
    layoutChanger {
      ...LayoutChanger
    }
    alignHtml
    customHtmlWidth
  }
`
