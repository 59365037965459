import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { cloneDeep } from "lodash"

import Image from "../utils/Image"
import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Button from "../atoms/Button"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"

import InfoCard from "../molecules/InfoCard"
import SectionTitle from "../molecules/SectionTitle"

const InfoCards = ({
  section,
  sectionTitle,
  flexGrid,
  infoCards,
  button,
  card,
  infoCardsTitleSize,
  infoCardsTitleColor,
  cardsTheme = "default",
  cardsBackground = 0,
  cardsShadow = "md",
  cardsAlign = "left",
  iconLocation = "top",
  image,
  infoCardsImageLocation,
  imageLocation,
  iconSize,
  openModalSectionById,
}) => {
  let flexGridProps = cloneDeep(flexGrid),
    flexProps = {}
  const hasImage = !!image
  if (hasImage) {
    flexGridProps.columns = {
      _: 1,
    }
    flexProps.flexDirection = {
      _: "column-reverse",
    }
    flexGridProps.gutter = hasImage ? { _: 3, md: 4 } : flexGridProps.gutter
    if (imageLocation === "left" || infoCardsImageLocation === "left")
      flexProps.flexDirection.md = "row"
    else flexProps.flexDirection.md = "row-reverse"
  }

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <Flex width="100%" {...flexProps} justifyContent="space-between">
        {hasImage && (
          <Box flex="0 0 48%" mt={{ _: 3, md: 0 }}>
            <Reveal>
              <Image {...image} />
            </Reveal>
          </Box>
        )}
        <FlexGrid flex={hasImage ? "0 0 48%" : "1 1 auto"} {...flexGridProps}>
          {infoCards?.map((infoCard, i) => (
            <InfoCard
              key={i}
              mx={cardsAlign === "center" ? "auto" : 0}
              iconLocation={iconLocation}
              iconSize={iconSize}
              align={cardsAlign}
              height={!hasImage ? "100%" : "auto"}
              {...infoCard}
              infoCardsTitleSize={infoCardsTitleSize}
              infoCardsTitleColor={infoCardsTitleColor}
              card={
                card || {
                  useCard: true,
                  bg: cardsBackground,
                  borderRadius: "md",
                  boxShadow: cardsShadow,
                  theme: cardsTheme,
                }
              }
            />
          ))}
        </FlexGrid>
      </Flex>
      {button && (
        <Flex mt={8} flexDirection="column" alignItems="center">
          <Button {...button} />
        </Flex>
      )}
    </Section>
  )
}

export default InfoCards

InfoCards.propTypes = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  infoCards: PropTypes.arrayOf(PropTypes.shape(InfoCard.strapiProps)),
  cardsAlign: PropTypes.oneOf(["left", "center"]).isRequired,
  iconLocation: PropTypes.oneOf(["top", "left"]).isRequired,
  card: PropTypes.shape(Card.strapiProps).isRequired,
  image: PropTypes.object,
  imageLocation: PropTypes.oneOf(["left", "right"]),
  infoCardsTitleSize: PropTypes.oneOf(["h2", "h3"]),
}

export const query = graphql`
  fragment InfoCards on Strapi_ComponentSectionsInfoCards {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    infoCards {
      ...InfoCard
    }
    button {
      ...Button
    }
    card {
      ...Card
    }
    infoCardsTitleSize
    infoCardsTitleColor
    cardsAlign
    iconLocation
    iconSize
    cardsTheme
    cardsBackground
    cardsShadow
    image {
      ...Image
      imageFile {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    infoCardsImageLocation: imageLocation
  }
`
