import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import { Text } from "../atoms/Typography"
import Button from "../atoms/Button"

const FilterTabs = ({
  menuOpen,
  options,
  anchorRef,
  name,
  allLabel,
  showFilterLabel,
  selected,
  center,
  alignMenuItems,
  setMenuOpen,
  setSelected,
  ...rest
}) => {
  const handleChange = event => {
    setSelected({ [allLabel]: event.target.textContent })
  }

  const handleSelectedTabStyle = option => {
    return {
      variant: "link",
      fontWeight: option === selected?.[allLabel] ? 900 : 400,
      fontSize: 3,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "170px",
      whiteSpace: "nowrap",
      display: "inline-block",
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        {showFilterLabel && (
          <Text fontSize={1} color="text">
            {name}
          </Text>
        )}
        <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-evenly">
          {options?.map((option, i) => {
            return (
              <Button
                key={i}
                ref={anchorRef}
                onClick={handleChange}
                mt={4}
                variant="link"
                hideArrow={true}
                {...rest}
                mr={6}
              >
                <Text {...handleSelectedTabStyle(option)}>{option}</Text>
              </Button>
            )
          })}
        </Flex>
      </Box>
    </Box>
  )
}

export default FilterTabs

FilterTabs.strapiProps = {
  name: PropTypes.string,
  allLabel: PropTypes.string.isRequired,
  showFilterLabel: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.object),
}

export const query = graphql`
  fragment FilterTabs on Strapi_ComponentAtomsFilter {
    name
    allLabel
    showFilterLabel
    tags {
      name
    }
  }
`
