import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import { space } from "styled-system"

import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import Card from "../atoms/Card"
import { Title, Paragraph, Label } from "../atoms/Typography"
import Button from "../atoms/Button"

import Checkmark from "../icons/Checkmark"

// TODO: Should be it's own atom
const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${space}
`

const PricingTier = ({
  name,
  gridName,
  largeText,
  gridLargeText,
  smallText,
  gridSmallText,
  list,
  button,
  largeTextColor,
  align,
  card,
}) => {
  // need to group items so <ul> and <li> tags are correct
  let groupedList = []
  list?.forEach(item => {
    const type = item.__typename || item.__component
    switch (type) {
      case "Strapi_ComponentUtilsText":
      case "utils.text":
        let lastList = groupedList[groupedList.length - 1]
        if (lastList?.type === "text") {
          lastList.data.push(item)
        } else {
          groupedList.push({ type: "text", data: [item] })
        }
        break
      case "Strapi_ComponentUtilsTitledList":
      case "utils.titled-list":
        groupedList.push({ type: "titled-list", data: item })
        break
      default:
        break
    }
  })

  return (
    <Card
      px={{ _: 5, sm: 6 }}
      py={6}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      {...card}
    >
      <div>
        {(name || gridName) && (
          <Title textAlign={align} variant="h3" mb={3}>
            {name || gridName}
          </Title>
        )}
        {(largeText || gridLargeText) && (
          <Paragraph textAlign={align} fontSize={5} color={largeTextColor}>
            {largeText || gridLargeText}
          </Paragraph>
        )}
        {(smallText || gridSmallText) && (
          <Paragraph textAlign={align} mt={4}>
            {smallText || gridSmallText}
          </Paragraph>
        )}
        {groupedList && (
          <Box mt={6}>
            {groupedList.map((group, i) => {
              switch (group.type) {
                case "text":
                  return (
                    <StyledList
                      mb={i === groupedList.length - 1 ? 0 : 6}
                      key={i}
                    >
                      {group.data?.map((textItem, i) => (
                        <Flex
                          as="li"
                          alignItems="center"
                          justifyContent={align}
                          mb={i === group.data.length - 1 ? 0 : 5}
                          key={i}
                        >
                          <Checkmark flexShrink="0" mr={3} />
                          <Paragraph as="span">{textItem.text}</Paragraph>
                        </Flex>
                      ))}
                    </StyledList>
                  )
                case "titled-list":
                  return (
                    <Box mb={i === groupedList.length - 1 ? 0 : 6} key={i}>
                      <Label color="text" mb={3}>
                        {group.data?.title}
                      </Label>
                      <StyledList>
                        {group.data?.list?.map((textItem, i) => (
                          <Flex
                            as="li"
                            alignItems="center"
                            mb={i === group.data.list.length - 1 ? 0 : 3}
                            key={i}
                          >
                            <Checkmark flexShrink="0" mr={3} />
                            <Paragraph as="span">{textItem.text}</Paragraph>
                          </Flex>
                        ))}
                      </StyledList>
                    </Box>
                  )
                default:
                  return null
              }
            })}
          </Box>
        )}
      </div>
      {button && (
        <Button
          mx={align === "center" ? "auto" : 0}
          {...button}
          mb={3}
          mt={6}
        />
      )}
    </Card>
  )
}

export default PricingTier

PricingTier.strapiProps = {
  name: PropTypes.string,
  largeText: PropTypes.string,
  smallText: PropTypes.string,
  style: PropTypes.oneOf(["primary", "secondary"]),
  button: PropTypes.shape(Button.strapiProps),
  list: PropTypes.arrayOf(PropTypes.object),
}

PricingTier.propTypes = {
  ...PricingTier.strapiProps,
  ...Card.propTypes,
}

export const query = graphql`
  fragment PricingTier on Strapi_ComponentMoleculesPricingTier {
    name
    largeText
    smallText
    style
    list {
      __typename
      ... on Strapi_ComponentUtilsText {
        text
      }
      ... on Strapi_ComponentUtilsTitledList {
        title
        list {
          text
        }
      }
    }
    button {
      ...Button
    }
  }
`
