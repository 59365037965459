import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Card from "../atoms/Card"

import SectionTitle from "../molecules/SectionTitle"
import BigImageCard from "../molecules/BigImageCard"

const BigImageCards = ({
  section,
  sectionTitle,
  flexGrid,
  bigImageCards,
  card,
  textBackground,
  bigImageCardsTextBackground,
  textVisibility,
  bigImageCardsTextVisibility,
  alignText,
  bigImageCardsAlignText,
  overlayOpacity,
  cardTitleVariant,
  cardTitleColor,
  useCardFront,
  imageWidth,
  imageRatio,
}) => {
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FlexGrid {...flexGrid}>
        {bigImageCards &&
          bigImageCards.map((imageCard, i) => {
            return (
              <BigImageCard
                key={i}
                {...imageCard}
                alignText={bigImageCardsAlignText || alignText}
                card={{ ...card, useCard: useCardFront }}
                textVisibility={bigImageCardsTextVisibility || textVisibility}
                textBackground={bigImageCardsTextBackground || textBackground}
                overlayOpacity={overlayOpacity}
                cardTitle={{
                  cardTitleVariant,
                  cardTitleColor,
                }}
                imageWidth={imageWidth}
                imageRatio={imageRatio === "square" ? 1 : 0.75}
              />
            )
          })}
      </FlexGrid>
    </Section>
  )
}

export default BigImageCards

BigImageCards.strapiProps = {
  id: PropTypes.string.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  bigImageCards: PropTypes.arrayOf(PropTypes.shape(BigImageCard.strapiProps)),
  alignText: PropTypes.oneOf(["left", "center"]),
  textVisibility: PropTypes.oneOf(["none", "onHover", "visible"]),
  textBackground: PropTypes.oneOf(["solid", "gradient"]),
  overlayOpacity: PropTypes.number,
  cardTitleVariant: PropTypes.oneOf(["h2", "h3"]),
  cardTitleColor: PropTypes.oneOf(["text", "primary", "secondary"]),
  useCardFront: PropTypes.bool,
  imageWidth: PropTypes.oneOf(["full", "default"]),
  imageRatio: PropTypes.oneOf(["default", "square"]),
}

BigImageCards.propTypes = {
  ...BigImageCards.strapiProps,
}

export const query = graphql`
  fragment BigImageCards on Strapi_ComponentSectionsBigImageCards {
    id
    section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    card {
      ...Card
    }
    bigImageCards {
      ...BigImageCard
    }
    bigImageCardsAlignText: alignText
    bigImageCardsTextVisibility: textVisibility
    bigImageCardsTextBackground: textBackground
    overlayOpacity
    cardTitleVariant
    cardTitleColor
    useCardFront
    imageWidth
    imageRatio
  }
`
