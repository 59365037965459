import React from "react"
import { graphql } from "gatsby"
import { kebabCase } from "lodash"

import Article from "../sections/Article"
import BigImageCards from "../sections/BigImageCards"
import BlogGrid from "../sections/BlogGrid"
import ButtonGrid from "../sections/ButtonGrid"
import Careers from "../sections/Careers"
import CareersAts from "../sections/CareersAts"
import Contact from "../sections/Contact"
import ContactForm from "../sections/ContactForm"
import Cta from "../sections/Cta"
import CtaInfoCards from "../sections/CtaInfoCards"
import CustomHtml from "../sections/CustomHtml"
import DynamicTitle from "../sections/DynamicTitle"
import Faqs from "../sections/Faqs"
import ImageCards from "../sections/ImageCards"
import ImageCarousel from "../sections/ImageCarousel"
import ImageGrid from "../sections/ImageGrid"
import ImageSection from "../sections/Image"
import ImageTextSection from "../sections/ImageText/ImageTextSection"
import ImageTextCarousel from "../sections/ImageTextCarousel/ImageTextCarousel"
import InfoCards from "../sections/InfoCards"
import InteractiveInfoCards from "../sections/InteractiveInfoCards"
import LogoCarousel from "../sections/LogoCarousel"
import LogoGrid from "../sections/LogoGrid"
import NewsletterSignup from "../sections/NewsletterSignup"
import PortfolioGrid from "../sections/PortfolioGrid"
import Pricing from "../sections/Pricing"
import PricingGrid from "../sections/PricingGrid"
import QuoteCarousel from "../sections/QuoteCarousel"
import Stats from "../sections/Stats"
import TeamGrid from "../sections/TeamGrid"
import TeamMember from "../sections/TeamMember"
import Text from "../sections/Text"
import ThinCta from "../sections/ThinCta"
import Twitter from "../sections/Twitter"

export const getSectionId = section => {
  if (section.__component)
    return `sections.${section.__component}.${section.id}`
  if (section.__typename)
    return `sections.${kebabCase(section.__typename.slice(24))}.${section.id}`
  // slice(24) removes "Strapi_ComponentSections"
  else return ""
}

const Sections = ({ section }) => {
  if (section.section) {
    if (section.section.hidden) {
      return null
    }
    section.section.sectionId = getSectionId(section)
  }

  switch (section.__typename || section.__component) {
    case "Strapi_ComponentSectionsImageText": // Fallthrough
    case "sections.image-text":
      return <ImageTextSection {...section} />
    case "Strapi_ComponentSectionsImage": // Fallthrough
    case "sections.image":
      return <ImageSection {...section} />
    case "Strapi_ComponentSectionsCta":
    case "sections.cta":
      return <Cta {...section} />
    case "Strapi_ComponentSectionsCtaInfoCards":
    case "sections.cta-info-cards":
      return <CtaInfoCards {...section} />
    case "Strapi_ComponentSectionsInfoCards":
    case "sections.info-cards":
      return <InfoCards {...section} />
    case "Strapi_ComponentSectionsTwitter":
    case "sections.twitter":
      return <Twitter {...section} />
    case "Strapi_ComponentSectionsButtonGrid":
    case "sections.button-grid":
      return <ButtonGrid {...section} />
    case "Strapi_ComponentSectionsBlogGrid":
    case "sections.blog-grid":
      return <BlogGrid {...section} />
    case "Strapi_ComponentSectionsNewsletterSignup":
    case "sections.newsletter-signup":
      return <NewsletterSignup {...section} />
    case "Strapi_ComponentSectionsTeamGrid":
    case "sections.team-grid":
      return <TeamGrid {...section} />
    case "Strapi_ComponentSectionsTeamMember":
    case "sections.team-member":
      return <TeamMember {...section} />
    case "Strapi_ComponentSectionsLogoGrid":
    case "sections.logo-grid":
      return <LogoGrid {...section} />
    case "Strapi_ComponentSectionsImageCarousel":
    case "sections.image-carousel":
      return <ImageCarousel {...section} />
    case "Strapi_ComponentSectionsStats":
    case "sections.stats":
      return <Stats {...section} />
    case "Strapi_ComponentSectionsContact":
    case "sections.contact":
      return <Contact {...section} />
    case "Strapi_ComponentSectionsCareers":
    case "sections.careers":
      return <Careers {...section} />
    case "Strapi_ComponentSectionsCareersAts":
    case "sections.careers-ats":
      return <CareersAts {...section} />
    case "Strapi_ComponentSectionsPricing":
    case "sections.pricing":
      return <Pricing {...section} />
    case "Strapi_ComponentSectionsPricingGrid":
    case "sections.pricing-grid":
      return <PricingGrid {...section} />
    case "Strapi_ComponentSectionsContactForm":
    case "sections.contact-form":
      return <ContactForm {...section} />
    case "Strapi_ComponentSectionsDynamicTitle":
    case "sections.dynamic-title":
      return <DynamicTitle {...section} />
    case "Strapi_ComponentSectionsLogoCarousel":
    case "sections.logo-carousel":
      return <LogoCarousel {...section} />
    case "Strapi_ComponentSectionsCustomHtml":
    case "sections.custom-html":
      return <CustomHtml {...section} />
    case "Strapi_ComponentSectionsPortfolioGrid":
    case "sections.portfolio-grid":
      return <PortfolioGrid {...section} />
    case "Strapi_ComponentSectionsArticle":
    case "sections.article":
      return <Article {...section} />
    case "Strapi_ComponentSectionsQuoteCarousel":
    case "sections.quote-carousel":
      return <QuoteCarousel {...section} />
    case "Strapi_ComponentSectionsImageTextCarousel":
    case "sections.image-text-carousel":
      return <ImageTextCarousel {...section} />
    case "Strapi_ComponentSectionsFaqs":
    case "sections.faqs":
      return <Faqs {...section} />
    case "Strapi_ComponentSectionsImageGrid":
    case "sections.image-grid":
      return <ImageGrid {...section} />
    case "Strapi_ComponentSectionsImageCards":
    case "sections.image-cards":
      return <ImageCards {...section} />
    case "Strapi_ComponentSectionsBigImageCards":
    case "sections.big-image-cards":
      return <BigImageCards {...section} />
    case "Strapi_ComponentSectionsInteractiveInfoCards":
    case "sections.interactive-info-cards":
      return <InteractiveInfoCards {...section} />
    case "Strapi_ComponentSectionsText":
    case "sections.text":
      return <Text {...section} />
    case "Strapi_ComponentSectionsThinCta":
    case "sections.thin-cta":
      return <ThinCta {...section} />
    default:
      return null
  }
}

export default Sections

export var query = graphql`
  fragment Sections on Strapi_PageSectionsDynamicZone {
    __typename
    ... on Strapi_ComponentSectionsImageText {
      ...ImageTextSection
    }
    ... on Strapi_ComponentSectionsImage {
      ...ImageSection
    }
    ... on Strapi_ComponentSectionsCta {
      ...Cta
    }
    ... on Strapi_ComponentSectionsTwitter {
      ...Twitter
    }
    ... on Strapi_ComponentSectionsInfoCards {
      ...InfoCards
    }
    ... on Strapi_ComponentSectionsButtonGrid {
      ...ButtonGrid
    }
    ... on Strapi_ComponentSectionsBlogGrid {
      ...BlogGrid
    }
    ... on Strapi_ComponentSectionsNewsletterSignup {
      ...NewsletterSignup
    }
    ... on Strapi_ComponentSectionsTeamGrid {
      ...TeamGrid
    }
    ... on Strapi_ComponentSectionsTeamMember {
      ...TeamMember
    }
    ... on Strapi_ComponentSectionsLogoGrid {
      ...LogoGrid
    }
    ... on Strapi_ComponentSectionsImageCarousel {
      ...ImageCarousel
    }
    ... on Strapi_ComponentSectionsStats {
      ...Stats
    }
    ... on Strapi_ComponentSectionsContact {
      ...Contact
    }
    ... on Strapi_ComponentSectionsCareers {
      ...Careers
    }
    ... on Strapi_ComponentSectionsCareersAts {
      ...CareersAts
    }
    ... on Strapi_ComponentSectionsPricing {
      ...Pricing
    }
    ... on Strapi_ComponentSectionsPricingGrid {
      ...PricingGrid
    }
    ... on Strapi_ComponentSectionsContactForm {
      ...ContactForm
    }
    ... on Strapi_ComponentSectionsDynamicTitle {
      ...DynamicTitle
    }
    ... on Strapi_ComponentSectionsLogoCarousel {
      ...LogoCarousel
    }
    ... on Strapi_ComponentSectionsCustomHtml {
      ...CustomHtml
    }
    ... on Strapi_ComponentSectionsPortfolioGrid {
      ...PortfolioGrid
    }
    ... on Strapi_ComponentSectionsArticle {
      ...Article
    }
    ... on Strapi_ComponentSectionsQuoteCarousel {
      ...QuoteCarousel
    }
    ... on Strapi_ComponentSectionsImageTextCarousel {
      ...ImageTextCarousel
    }
    ... on Strapi_ComponentSectionsFaqs {
      ...Faqs
    }
    ... on Strapi_ComponentSectionsImageGrid {
      ...ImageGrid
    }
    ... on Strapi_ComponentSectionsImageCards {
      ...ImageCards
    }
    ... on Strapi_ComponentSectionsBigImageCards {
      ...BigImageCards
    }
    ... on Strapi_ComponentSectionsCtaInfoCards {
      ...CtaInfoCards
    }
    ... on Strapi_ComponentSectionsInteractiveInfoCards {
      ...InteractiveInfoCards
    }
    ... on Strapi_ComponentSectionsText {
      ...Text
    }
    ... on Strapi_ComponentSectionsThinCta {
      ...ThinCta
    }
  }
`
